import React, {ReactNode} from "react";
import {RoutePermittedRole} from "../shared/constants/AppConst";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import {Module} from "../types/models/Common";
import StoreIcon from '@mui/icons-material/Store';
import GroupIcon from '@mui/icons-material/Group';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import SummarizeIcon from '@mui/icons-material/Summarize';

export interface RouterConfigData {
    id: string;
    title: string;
    messageId: string;
    icon?: string | ReactNode;
    type: "item" | "group" | "collapse" | "divider";
    children?: RouterConfigData[];
    permittedRole?: RoutePermittedRole;
    color?: string;
    url?: string;
    exact?: boolean;
    count?: number;
    module?: Module;
}

const routesConfig: RouterConfigData[] = [
    {
        id: "dashboard",
        title: "Dashboard",
        messageId: "sidebar.dashboard",
        type: "item",
        icon: <InsertChartIcon/>,
        url: "/company/dashboard",
        module: "company"
    },
    {
        id: "request",
        title: "Pedidos",
        messageId: "sidebar.request",
        type: "group",
        module: "company",
        children: [
            {
                id: "request-list",
                title: "Pedidos",
                messageId: "sidebar.request.list",
                type: "item",
                icon: <FastfoodIcon/>,
                url: "/company/request/list",
                module: "company"
            },
            {
                id: "request-panel",
                title: "Pedidos",
                messageId: "sidebar.request.panel",
                type: "item",
                icon: <ViewKanbanIcon/>,
                url: "/company/request/panel",
                module: "company",
                permittedRole: RoutePermittedRole.ORDER_PANEL
            }
        ]
    },
    {
        id: "request-support",
        title: "Pedidos",
        messageId: "sidebar.request",
        type: "item",
        icon: <FastfoodIcon/>,
        url: "/support/request/list",
        module: "support"
    },
    {
        id: "summary-support",
        title: "Resumo",
        messageId: "sidebar.summary",
        type: "item",
        icon: <SummarizeIcon/>,
        url: "/support/summary",
        module: "support"
    },
    {
        id: "register",
        title: "Cadastro",
        messageId: "sidebar.register",
        type: "group",
        children: [
            {
                id: "company",
                title: "Empresas",
                messageId: "sidebar.register.company",
                type: "item",
                icon: <StoreIcon/>,
                url: "/support/company/list",
            },
            {
                id: "catalog",
                title: "Catalogo",
                messageId: "sidebar.catalog",
                type: "item",
                icon: <NoteAltIcon/>,
                url: "/support/catalog/list",
                module: "support"
            },
            {
                id: "anotaai",
                title: "Anota Ai",
                messageId: "sidebar.register.anotaai",
                type: "item",
                icon: <StoreIcon/>,
                url: "/support/anotaai",
            },
            {
                id: "robinfood",
                title: "Robin Food",
                messageId: "sidebar.register.robinfood",
                type: "item",
                icon: <StoreIcon/>,
                url: "/support/robinfood",
            },
            {
                id: "user-seller",
                title: "Usuários",
                messageId: "sidebar.register.users",
                type: "item",
                icon: <GroupIcon/>,
                url: "/support/user/list",
                permittedRole: RoutePermittedRole.SUPPORT_VIEW_USER
            }
        ],
        module: "support"
    },
    {
        id: "company-register",
        title: "Cadastro",
        messageId: "sidebar.register",
        type: "group",
        children: [
            {
                id: "catalog",
                title: "Catalogo",
                messageId: "sidebar.catalog",
                type: "item",
                icon: <NoteAltIcon/>,
                url: "/company/catalog/list",
                module: "company"
            },
            {
                id: "user-company",
                title: "Usuários",
                messageId: "sidebar.register.users",
                type: "item",
                icon: <GroupIcon/>,
                url: "/company/user/list",
                permittedRole: RoutePermittedRole.COMPANY_VIEW_USER
            },
            {
                id: "company-register",
                title: "Cadastro",
                messageId: "sidebar.marketplaces",
                icon: <StoreIcon/>,
                type: "collapse",
                permittedRole: RoutePermittedRole.MARKETPLACE,
                children: [
                    {
                        id: "robinfood",
                        title: "Robin Food",
                        messageId: "sidebar.register.robinfood",
                        type: "item",
                        url: "/company/robinfood",
                        permittedRole: RoutePermittedRole.MARKETPLACE_ROBINFOOD
                    },
                    {
                        id: "anotaai",
                        title: "AnotaAi",
                        messageId: "sidebar.register.anotaai",
                        type: "item",
                        url: "/company/anotaai",
                        permittedRole: RoutePermittedRole.MARKETPLACE_ANOTAAI
                    },
                ]
            },
        ],
        module: "company"
    },
];
export default routesConfig;
